import {
    SET_DATA_CLIENTE,
    SET_ERROR_QR
} from '../types'

const initialState = {
    data: {},
    message: '',
    error: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DATA_CLIENTE:
            return {
                ...action.payload
            }
        default: return state
    }
}