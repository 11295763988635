import axios from 'axios'
import React from 'react'
import update from 'immutability-helper'

import * as XLSX from 'xlsx'
import ReactHtmlTableToExcel from 'react-html-table-to-excel'

class Reservas extends React.Component{
    constructor (props){
        super (props)
        this.state = {
            localidad: '',
            fecha: '',
            mfecha: false,
            mhora: false,
            reservas: [],
            loading: false
        }
    }

    componentDidMount = () => {
        this.setState(update(this.state, {
            loading: { $set: true }
        }))
        axios.get ('/reservas')
            .then ((res) => {
                this.setState (update (this.state, {
                    reservas: { $set: res.data.reservas },
                    loading: { $set: false }
                }))
            }).catch((err) => {
                this.setState(update(this.state, {
                    loading: { $set: false }
                }))
            })
    }

    handleselect = (e) => {
        e.preventDefault ()

        let value = e.target.value
        let id = e.target.id

        this.setState(update(this.state, {
            loading: { $set: true }
        }))

        if (id === 'localidad' && value === '0') {
            axios.get('/reservas')
                .then((res) => {
                    this.setState(update(this.state, {
                        reservas: { $set: res.data.reservas },
                        loading: { $set: false },
                        mfecha: { $set: false }
                    }))
                }).catch((err) => {
                    this.setState(update(this.state, {
                        loading: { $set: false }
                    }))
                })
        } else  if ((id === 'localidad' && value !== '0') || (id === 'fecha' && value === '0')){
            axios.get(`/reservas/${value}`)
                .then((res) => {
                    this.setState(update(this.state, {
                        reservas: { $set: res.data.reservas },
                        loading: { $set: false },
                        mfecha: { $set: true },
                        localidad: {$set: value}
                    }))
                }).catch((err) => {
                    this.setState(update(this.state, {
                        loading: { $set: false }
                    }))
                })
        } if (id === 'fecha' && value !== '0') {
            axios.get(`/reservas/${this.state.localidad}/${value}`)
                .then((res) => {
                    this.setState(update(this.state, {
                        reservas: { $set: res.data.reservas },
                        loading: { $set: false }
                    }))
                }).catch((err) => {
                    this.setState(update(this.state, {
                        loading: { $set: false }
                    }))
                })
        }
    }

    obtenerfecha = (num) => {
        return (new Date(Date.now() + (24 * 60 * 60 * 1000 * num))).toLocaleDateString("es-PE", { weekday: 'long', yeaer: 'numeric', month: 'long', day: 'numeric' })
    }

    render (){
        return (
            <div className='wrapper-reservas'>
                <h6>Lista de reservas:</h6>
                <div className='row'>
                    <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        <div className='mbr-3'>
                            <label className='form-label' htmlFor='localidad'>Filtrar por local</label>
                            <select className='form-select mb-3' id='localidad' onChange={this.handleselect.bind(this)}>
                                <option value='0'>Todos</option>
                                <option value='miraflores'>Miraflores</option>
                                <option value='surco'>Surco</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        {
                            this.state.mfecha ? (
                                <div className='mbr-3'>
                                    <label className='form-label' htmlFor='fecha'>Filtrar por fecha</label>
                                    <select className='form-select mb-3' id='fecha' onChange={this.handleselect.bind(this)}>
                                        <option value='0'>Todas</option>
                                        <option value={this.obtenerfecha(0)}>{this.obtenerfecha(0)} (Hoy)</option>
                                        <option value={this.obtenerfecha(1)}>{this.obtenerfecha(1)} (Mañana)</option>
                                        <option value={this.obtenerfecha(2)}>{this.obtenerfecha(2)}</option>
                                        <option value={this.obtenerfecha(3)}>{this.obtenerfecha(3)}</option>
                                        <option value={this.obtenerfecha(4)}>{this.obtenerfecha(4)}</option>
                                        <option value={this.obtenerfecha(5)}>{this.obtenerfecha(5)}</option>
                                        <option value={this.obtenerfecha(6)}>{this.obtenerfecha(6)}</option>
                                        <option value={this.obtenerfecha(7)}>{this.obtenerfecha(7)}</option>
                                        <option value={this.obtenerfecha(8)}>{this.obtenerfecha(8)}</option>
                                        <option value={this.obtenerfecha(9)}>{this.obtenerfecha(9)}</option>
                                        <option value={this.obtenerfecha(10)}>{this.obtenerfecha(10)}</option>
                                        <option value={this.obtenerfecha(11)}>{this.obtenerfecha(11)}</option>
                                        <option value={this.obtenerfecha(12)}>{this.obtenerfecha(12)}</option>
                                        <option value={this.obtenerfecha(13)}>{this.obtenerfecha(13)}</option>
                                        <option value={this.obtenerfecha(14)}>{this.obtenerfecha(14)}</option>
                                        <option value={this.obtenerfecha(15)}>{this.obtenerfecha(15)}</option>
                                        <option value={this.obtenerfecha(16)}>{this.obtenerfecha(16)}</option>
                                        <option value={this.obtenerfecha(17)}>{this.obtenerfecha(17)}</option>
                                        <option value={this.obtenerfecha(18)}>{this.obtenerfecha(18)}</option>
                                        <option value={this.obtenerfecha(19)}>{this.obtenerfecha(19)}</option>
                                        <option value={this.obtenerfecha(20)}>{this.obtenerfecha(20)}</option>
                                        <option value={this.obtenerfecha(21)}>{this.obtenerfecha(21)}</option>
                                        <option value={this.obtenerfecha(22)}>{this.obtenerfecha(22)}</option>
                                        <option value={this.obtenerfecha(23)}>{this.obtenerfecha(23)}</option>
                                        <option value={this.obtenerfecha(24)}>{this.obtenerfecha(24)}</option>
                                        <option value={this.obtenerfecha(25)}>{this.obtenerfecha(25)}</option>
                                        <option value={this.obtenerfecha(26)}>{this.obtenerfecha(26)}</option>
                                        <option value={this.obtenerfecha(27)}>{this.obtenerfecha(27)}</option>
                                        <option value={this.obtenerfecha(28)}>{this.obtenerfecha(28)}</option>
                                        <option value={this.obtenerfecha(29)}>{this.obtenerfecha(29)}</option>
                                        <option value={this.obtenerfecha(30)}>{this.obtenerfecha(30)}</option>
                                        <option value={this.obtenerfecha(31)}>{this.obtenerfecha(31)}</option>
                                    </select>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        
                    </div>
                </div>
                {
                    !this.state.loading ? (
                        <table className='table' id='tablereservas'>
                            <thead>
                                <tr style={{textAlign: 'center'}}>
                                    <th>#</th>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                    <th>Nombres</th>
                                    <th>Teléfono</th>
                                    <th>Correo</th>
                                    <th>Invitados</th>
                                    <th>Local</th>
                                    <th>Pedido de reserva</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.reservas.length > 0 ? (
                                        this.state.reservas.map((reserva, index) => {
                                            return (
                                                <tr key={index} style={{ background: (index % 2) ? '#ffffff' : '#efefef' }}>
                                                    <th>{reserva.reserva}</th>
                                                    <th>{reserva.fecha}</th>
                                                    <th>{reserva.hora}</th>
                                                    <th>{reserva.nombres}, {reserva.apellidos}</th>
                                                    <th>{reserva.telefono}</th>
                                                    <th>{reserva.correo}</th>
                                                    <th>{reserva.invitados}</th>
                                                    <th>{reserva.local}</th>
                                                    <th>{reserva.registro ? reserva.registro.split ('T')[0] : ''}</th>
                                                </tr>
                                            )
                                        })
                                    ) : null
                                }
                            </tbody>
                        </table>

                    ): (
                        <div className = 'wrapper-cargando'>
                            <h4>Cargando datos!</h4>
                        </div >
                    )
                }
                <ReactHtmlTableToExcel
                    id='exportar'
                    className='btn btn-primary'
                    table='tablereservas'
                    filename={`hoja-reservas`}
                    sheet='pagina 1'
                    buttonText='Exportar a Excel' />
            </div>
        )
    }
}

export default Reservas