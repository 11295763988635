import React from 'react'
import update from 'immutability-helper'

import check from '../../images/check.png'
import checkbox from '../../images/checkbox.png'
import trash from '../../images/delete.png'

import axios from 'axios'

class Fechas extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            fecha: 'Seleccionar',
            check: false,
            dia: true,
            doce: false,
            una: false,
            dos: false,
            tres: false,
            cuatro: false,
            cinco: false,
            seis: false,
            siete: false,
            ocho: false,
            nueve: false,
            diez: false,
            lista: [], 
            loading: false
        }
    }

    componentDidMount = () => {
        this.setState (update (this.state, {
            loading: {$set: true}
        }))
        axios.get ('/fechas/bloqueadas')
            .then ((res) => {
                console.log (res.data)
                this.setState (update (this.state, {
                    lista: { $set: res.data.fechas },
                    loading: { $set: false }
                }))
            }).catch((err) => {
                this.setState(update(this.state, {
                    loading: { $set: false }
                }))
            })
    }

    agregar = (e) => {
        e.preventDefault()

        let data 
        if (this.state.dia) {
            data = {
                dia: this.state.fecha
            }
        }else{
            data = {
                dia: this.state.fecha,
                horas: [
                    { doce: this.state.doce },
                    { una: this.state.una },
                    { dos: this.state.dos },
                    { tres: this.state.tres },
                    { cuatro: this.state.cuatro },
                    { cinco: this.state.cinco },
                    { seis: this.state.seis },
                    { siete: this.state.siete },
                    { ocho: this.state.ocho },
                    { nueve: this.state.nueve },
                    { diez: this.state.diez },
                ]
            }
        }
        console.log (data)

        this.setState(update(this.state, {
            loading: { $set: true }
        }))
        axios.post ('/fechas/bloqueadas', data)
            .then((res) => {
                console.log(res.data)
                this.setState(update(this.state, {
                    fecha: { $set: 'Seleccionar' },
                    check: { $set: false },
                    dia: { $set: true },
                    doce: { $set: false },
                    uno: { $set: false },
                    dos: { $set: false },
                    tres: { $set: false },
                    cuatro: { $set: false },
                    cinco: { $set: false },
                    seis: { $set: false },
                    siete: { $set: false },
                    ocho: { $set: false },
                    nueve: { $set: false },
                    diez: { $set: false },
                    lista: { $set: res.data.fechas },
                    loading: { $set: false }
                }))
            }).catch((err) => {
                this.setState(update(this.state, {
                    loading: { $set: false }
                }))
            })
    }

    handleselect = (e) => {
        e.preventDefault()

        let value = e.target.value

        if (value !== '0'){
            this.setState(update(this.state, {
                fecha: { $set: value },
                check: { $set: true }
            }))
        } else {
            this.setState(update(this.state, {
                fecha: { $set: '' },
                check: { $set: false }
            }))
        }
    }

    obtenerfecha = (num) => {
        return (new Date(Date.now() + (24 * 60 * 60 * 1000 * num))).toLocaleDateString("es-PE", { weekday: 'long', yeaer: 'numeric', month: 'long', day: 'numeric' })
    }

    todoeldia = (e) => {
        e.preventDefault ()

        this.setState (update (this.state, {
            dia: {$set: !this.state.dia}
        }))
    }

    selecthora = (e) => {
        e.preventDefault ()

        let id = e.target.id

        if (id === 'doce') {
            this.setState(update(this.state, {
                doce: { $set: !this.state.doce }
            }))
        } else if (id === 'uno') {
            this.setState(update(this.state, {
                uno: { $set: !this.state.uno }
            }))
        } else if (id === 'dos') {
            this.setState(update(this.state, {
                dos: { $set: !this.state.dos }
            }))
        } else if (id === 'tres') {
            this.setState(update(this.state, {
                tres: { $set: !this.state.tres }
            }))
        } else if (id === 'cuatro') {
            this.setState(update(this.state, {
                cuatro: { $set: !this.state.cuatro }
            }))
        } else if (id === 'cinco') {
            this.setState(update(this.state, {
                cinco: { $set: !this.state.cinco }
            }))
        } else if (id === 'seis') {
            this.setState(update(this.state, {
                seis: { $set: !this.state.seis }
            }))
        } else if (id === 'siete') {
            this.setState(update(this.state, {
                siete: { $set: !this.state.siete }
            }))
        } else if (id === 'ocho') {
            this.setState(update(this.state, {
                ocho: { $set: !this.state.ocho }
            }))
        } else if (id === 'nueve') {
            this.setState(update(this.state, {
                nueve: { $set: !this.state.nueve }
            }))
        } else if (id === 'diez') {
            this.setState(update(this.state, {
                diez: { $set: !this.state.diez }
            }))
        }
    }

    borrarfechahora = (e) => {
        e.preventDefault ()

        let id = e.target.id

        this.setState(update(this.state, {
            loading: { $set: true }
        }))
        axios.delete(`/fechas/bloqueadas/${id}`)
            .then ((res) => {
                console.log (res.data)
                this.setState (update (this.state, {
                    lista: {$set: res.data.fechas},
                    loading: { $set: false }
                }))
            }).catch((err) => {
                this.setState(update(this.state, {
                    loading: { $set: false }
                }))
            })
    }

    render (){
        return (
            <div className='wrapper-fechas'>
                <h6>Fechas que no se podrá reservar:</h6>
                <div className='row'>
                    <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                        <div className='mbr-3'>
                            <label htmlFor='fecha'>Seleccionar fecha</label>
                            <select className='form-select mb-3' id='fecha' onChange={this.handleselect.bind(this)}>
                                <option value='0'>{this.state.fecha}</option>
                                <option value={this.obtenerfecha(1)}>{this.obtenerfecha(1)} (Mañana)</option>
                                <option value={this.obtenerfecha(2)}>{this.obtenerfecha(2)}</option>
                                <option value={this.obtenerfecha(3)}>{this.obtenerfecha(3)}</option>
                                <option value={this.obtenerfecha(4)}>{this.obtenerfecha(4)}</option>
                                <option value={this.obtenerfecha(5)}>{this.obtenerfecha(5)}</option>
                                <option value={this.obtenerfecha(6)}>{this.obtenerfecha(6)}</option>
                                <option value={this.obtenerfecha(7)}>{this.obtenerfecha(7)}</option>
                                <option value={this.obtenerfecha(8)}>{this.obtenerfecha(8)}</option>
                                <option value={this.obtenerfecha(9)}>{this.obtenerfecha(9)}</option>
                                <option value={this.obtenerfecha(10)}>{this.obtenerfecha(10)}</option>
                                <option value={this.obtenerfecha(11)}>{this.obtenerfecha(11)}</option>
                                <option value={this.obtenerfecha(12)}>{this.obtenerfecha(12)}</option>
                                <option value={this.obtenerfecha(13)}>{this.obtenerfecha(13)}</option>
                                <option value={this.obtenerfecha(14)}>{this.obtenerfecha(14)}</option>
                                <option value={this.obtenerfecha(15)}>{this.obtenerfecha(15)}</option>
                                <option value={this.obtenerfecha(16)}>{this.obtenerfecha(16)}</option>
                                <option value={this.obtenerfecha(17)}>{this.obtenerfecha(17)}</option>
                                <option value={this.obtenerfecha(18)}>{this.obtenerfecha(18)}</option>
                                <option value={this.obtenerfecha(19)}>{this.obtenerfecha(19)}</option>
                                <option value={this.obtenerfecha(20)}>{this.obtenerfecha(20)}</option>
                                <option value={this.obtenerfecha(21)}>{this.obtenerfecha(21)}</option>
                                <option value={this.obtenerfecha(22)}>{this.obtenerfecha(22)}</option>
                                <option value={this.obtenerfecha(23)}>{this.obtenerfecha(23)}</option>
                                <option value={this.obtenerfecha(24)}>{this.obtenerfecha(24)}</option>
                                <option value={this.obtenerfecha(25)}>{this.obtenerfecha(25)}</option>
                                <option value={this.obtenerfecha(26)}>{this.obtenerfecha(26)}</option>
                                <option value={this.obtenerfecha(27)}>{this.obtenerfecha(27)}</option>
                                <option value={this.obtenerfecha(28)}>{this.obtenerfecha(28)}</option>
                                <option value={this.obtenerfecha(29)}>{this.obtenerfecha(29)}</option>
                                <option value={this.obtenerfecha(30)}>{this.obtenerfecha(30)}</option>
                                <option value={this.obtenerfecha(31)}>{this.obtenerfecha(31)}</option>
                                <option value={this.obtenerfecha(32)}>{this.obtenerfecha(32)}</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12'>
                        {
                            this.state.check ? (
                                <div className='wrapper-todo'>
                                    <img src={this.state.dia ? checkbox : check} alt='seleccionado' onClick={this.todoeldia.bind(this)} />
                                    <p>Todo el día esta deshabilitado</p>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
                {
                    !this.state.dia ? (
                        <div className='horas'>
                            <label htmlFor='fecha'>Seleccionar fecha</label>
                            <div className='row'>
                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12'>
                                    <div className='wrapper-hora'>
                                        <img src={this.state.doce ? checkbox : check} alt='check' id='doce' onClick={this.selecthora.bind(this)}/>
                                        <p className='btn'>12:00 p.m</p>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12'>
                                    <div className='wrapper-hora'>
                                        <img src={this.state.uno ? checkbox : check} alt='check' id='uno' onClick={this.selecthora.bind(this)}/>
                                        <p className='btn'>01:00 p.m</p>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12'>
                                    <div className='wrapper-hora'>
                                        <img src={this.state.dos ? checkbox : check} alt='check' id='dos' onClick={this.selecthora.bind(this)}/>
                                        <p className='btn'>02:00 p.m</p>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12'>
                                    <div className='wrapper-hora'>
                                        <img src={this.state.tres ? checkbox : check} alt='check' id='tres' onClick={this.selecthora.bind(this)}/>
                                        <p className='btn'>03:00 p.m</p>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12'>
                                    <div className='wrapper-hora'>
                                        <img src={this.state.cuatro ? checkbox : check} alt='check' id='cuatro' onClick={this.selecthora.bind(this)}/>
                                        <p className='btn'>04:00 p.m</p>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12'>
                                    <div className='wrapper-hora'>
                                        <img src={this.state.cinco ? checkbox : check} alt='check' id='cinco' onClick={this.selecthora.bind(this)}/>
                                        <p className='btn'>05:00 p.m</p>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12'>
                                    <div className='wrapper-hora'>
                                        <img src={this.state.seis ? checkbox : check} alt='check' id='seis' onClick={this.selecthora.bind(this)}/>
                                        <p className='btn'>06:00 p.m</p>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12'>
                                    <div className='wrapper-hora'>
                                        <img src={this.state.siete ? checkbox : check} alt='check' id='siete' onClick={this.selecthora.bind(this)}/>
                                        <p className='btn'>07:00 p.m</p>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12'>
                                    <div className='wrapper-hora'>
                                        <img src={this.state.ocho ? checkbox : check} alt='check' id='ocho' onClick={this.selecthora.bind(this)}/>
                                        <p className='btn'>08:00 p.m</p>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12'>
                                    <div className='wrapper-hora'>
                                        <img src={this.state.nueve ? checkbox : check} alt='check' id='nueve' onClick={this.selecthora.bind(this)}/>
                                        <p className='btn'>09:00 p.m</p>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12'>
                                    <div className='wrapper-hora'>
                                        <img src={this.state.diez ? checkbox : check} alt='check' id='diez' onClick={this.selecthora.bind(this)}/>
                                        <p className='btn'>10:00 p.m</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                <button className='btn' onClick={this.agregar.bind(this) }>Agregar a lista</button>
                {
                    !this.state.loading ? (
                        <table className='table' id='tablefechas'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Fecha</th>
                                    <th>Horas</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.lista.length > 0 ? (
                                        this.state.lista.map((fechahora, index) => {
                                            return (
                                                <tr key={index} style={{ background: (index % 2) ? '#ffffff' : '#efefef' }}>
                                                    <th>{index + 1}</th>
                                                    <th>{fechahora.dia}</th>
                                                    <th>
                                                        {
                                                            fechahora.horas ? (
                                                                fechahora.horas.map((hora, index) => {
                                                                    return (
                                                                        <div>
                                                                            {hora.doce ? '12:00 p.m ' : null} {hora.una ? '01:00 p.m ' : null} {hora.dos ? '02:00 p.m ' : null} {hora.tres ? '03:00' : null}
                                                                            {hora.cuatro ? '04:00 p.m ' : null} {hora.cinco ? '05:00 p.m ' : null} {hora.seis ? '06:00 p.m ' : null} {hora.siete ? '07:00 p.m ' : null}
                                                                            {hora.ocho ? '08:00 p.m ' : null} {hora.nueve ? '09:00 p.m ' : null} {hora.diez ? '10:00 p.m ' : null}
                                                                        </div>
                                                                    )
                                                                })
                                                            ) : <div>Todo el día</div>
                                                        }
                                                    </th>
                                                    <th><img src={trash}id={fechahora.id} alt='borrar' onClick={this.borrarfechahora.bind(this)}/></th>
                                                </tr>
                                            )
                                        })
                                    ) : null
                                }
                            </tbody>
                        </table>
                    ): (
                        <div className = 'wrapper-cargando'>
                            <h4>Cargando datos!</h4>
                        </div >
                    )
                }
            </div>
        )
    }
}

export default Fechas