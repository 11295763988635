import {
    SET_DATA_SESSION,
    SET_ERROR_SESSION,
    SET_LOADING_SESSION,

    SET_UNAUTHENTICATED
} from '../types'

import { browserHistory } from 'react-router'

import axios from 'axios'

var $ = window.$

export const login = (datasesion) => (dispatch) => {
    dispatch({
        type: SET_LOADING_SESSION,
    })
    axios.post('/login', datasesion)
        .then((res) => {
            console.log(res.data)
            if (res.data.token) {
                setAuthorizationHeader(res.data.token)
                dispatch({
                    type: SET_DATA_SESSION,
                    payload: res.data
                })
                browserHistory.push('/')
            } else {
                alert('Ingreso mal su contraseña o correo, intentelo nuevamente!')
                dispatch (logoutUser())
            }
        }).catch((err) => {
            dispatch({
                type: SET_ERROR_SESSION,
                payload: err.data
            })
            alert('Algo salio mal, intentelo nuevamente!')
            dispatch(logoutUser())
        })
}

export const logoutUser = () => (dispatch) => {
    window.localStorage.removeItem('token')
    delete axios.defaults.headers.common['Authorization']
    dispatch({ type: SET_UNAUTHENTICATED })
    browserHistory.push('/login')
}

const setAuthorizationHeader = (token) => {
    window.localStorage.setItem('token', token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}