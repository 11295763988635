import React from 'react'
import update from 'immutability-helper'

import green from '../../images/green.png'
import red from '../../images/red.png'

import block from '../../images/reservas-bloqueadas.png'
import unblock from '../../images/reservas.png'

import axios from 'axios'

class Home extends React.Component {
    constructor (props){
        super (props)
        this.state = {
            habilitado: true,
            loading: false
        }
    }

    componentDidMount = () => {
        this.setState (update (this.state, {
            loading: {$set: true}
        }))
        axios.get ('/estado/modulo')
            .then((res) => {
                this.setState(update(this.state, {
                    habilitado: { $set: res.data.estado.habilitado },
                    loading: { $set: false }
                }))
            }).catch((err) => {
                this.setState(update(this.state, {
                    loading: { $set: false }
                }))
            })
    }

    habilitar = (e) => {
        this.setState(update(this.state, {
            loading: { $set: true }
        }))
        axios.post ('/estado/modulo')
            .then ((res) => {
                this.setState (update (this.state, {
                    habilitado: { $set: !this.state.habilitado },
                    loading: { $set: false }
                }))
            }).catch((err) => {
                this.setState(update(this.state, {
                    loading: { $set: false }
                }))
            })
    }

    render (){
        return (
            <div className='wrapper-habilitar'>
                {
                    !this.state.loading ? (
                        <div className='row'>
                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='wrapper-titulo'>
                                    <h4>Habilitar o deshabilitar módulo de reservas</h4>
                                </div>
                                <div className='estado'>
                                    <img src={this.state.habilitado ? green : red} alt={this.state.habilitado ? 'habilitado' : 'deshabilitado'}
                                        onClick={this.habilitar.bind(this)} />
                                    <label>({this.state.habilitado ? 'Habilitado' : 'Deshabilitado'} el módulo de reserva)</label>
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='wrapper-image'>
                                    {
                                        this.state.habilitado ? (
                                            <img src='https://lacabreraperu.com/images/unblock.png' alt='no bloqueadas' />
                                        ) : (
                                            <img src='https://lacabreraperu.com/images/block.png' alt='bloqueadas' />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='wrapper-cargando'>
                            <h4>Cargando datos!</h4>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Home