import {
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED,

    SET_LOADING_SESSION,
    SET_DATA_SESSION,
    SET_ERROR_SESSION
} from '../types'

const initialState = {
    authenticated: false,
    loading: false,
    token: '',
    usuario: {},
    message: '',
    error: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true
            }
        case SET_UNAUTHENTICATED:
            return {
                authenticated: false
            }
        case SET_DATA_SESSION:
            return {
                loading: false,
                authenticated: true,
                ...action.payload
            }
        case SET_LOADING_SESSION:
            return {
                loading: true,
                authenticated: false,
                ...action.payload
            }
        case SET_ERROR_SESSION:
            return {
                loading: false,
                authenticated: false,
                ...action.payload
            }
        default: return state
    }
}