import React from 'react';
import { Router, Route, browserHistory, IndexRoute } from 'react-router'
import axios from 'axios'

import AuthRoute from './util/AuthRoute'
import { Provider } from 'react-redux'

import store from './redux/store.js'

import { SET_AUTHENTICATED } from './redux/types'
import { logoutUser } from './redux/actions/userActions'

import './styles.css'

import AdminApp from './components/adminapp.js'

import Login from './components/login.js'

axios.defaults.baseURL = "https://us-central1-reservas-lacabrera.cloudfunctions.net/web"

let token = window.localStorage.getItem('token')

if (token) {
  store.dispatch({ type: SET_AUTHENTICATED })
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
} else {
  store.dispatch(logoutUser())
}

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={browserHistory}>

          <Route path='login' component={Login} />
          <AuthRoute path='/' component={AdminApp} />

        </Router>
      </Provider>
    )
  }
}

export default App;