import React from 'react'
import update from 'immutability-helper'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { login } from '../redux/actions/userActions.js'

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            correo: '',
            contrasenia: ''
        }
    }

    login(e) {
        e.preventDefault()

        let data = {
            'correo': this.state.correo,
            'contrasenia': this.state.contrasenia
        }

        this.props.login(data)
    }

    handleInput(e) {
        let id = e.target.id
        let value = e.target.value

        this.setState(update(this.state, {
            [id]: { $set: value }
        }))
    }

    render() {
        const {user: {loading}} = this.props

        let markuploading = (
            <div className='wrapping_card'>
                {
                    !loading ? (
                        <div>
                            <div className='card-header white'>
                                <h4>PANEL DE RESERVAS</h4>
                                <p>¡Inicia sesión con tu cuenta!</p>
                            </div>
                            <div className='card-body'>
                                <form onSubmit={this.login.bind(this)}>
                                    <div className='form-group'>
                                        <label htmlFor='correo'>Correo electrónico</label>
                                        <input type='email' className='form-control' aria-describedby='emailHelp'
                                            id='correo' placeholder='Correo electrónico' onChange={this.handleInput.bind(this)}
                                            value={this.state.correo} />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='contrasenia'>Contraseña</label>
                                        <input type='password' className='form-control' aria-describedby='passwordHelp'
                                            id='contrasenia' placeholder='xxxxxxxxxx' onChange={this.handleInput.bind(this)}
                                            value={this.state.contrasenia} />
                                    </div>
                                </form>
                            </div>
                            <div className='card-footer white'>
                                <div className='wrapping_footer'>
                                    <button type='submit' className='btn' onClick={this.login.bind(this)}>INGRESAR</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='card-header white'>
                            <h4>INICIANDO SESIÓN</h4>
                        </div>
                    )
                }
            </div>
        )

        return (
            <div className='container login'>
                <div className='wrapping_login'>
                    <div className='card shadow-sm p-3 mb-5 rounded'>
                        {markuploading}
                    </div>
                </div>
            </div>
        )
    }
}

Login.propTypes = {
    user: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapActionsToProps = {
    login
}

export default connect(mapStateToProps, mapActionsToProps)(Login)