export const SET_DATA_QR = 'SET_DATA_QR'
export const SET_ERROR_QR = 'SET_ERROR_QR'

export const SET_LOADING_SESSION = 'SET_LOADING_SESSION'
export const SET_DATA_SESSION = 'SET_DATA_SESSION'
export const SET_ERROR_SESSION = 'SET_ERROR_SESSION'

export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'

export const SET_DATA_CLIENTE = 'SET_DATA_CLIENTE'