import React from 'react'
import update from 'immutability-helper'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { logoutUser } from '../redux/actions/userActions.js'

import Home from './modulos/home.js'
import Reservas from './modulos/reservas.js'
import Fechas from './modulos/fechas.js'

class AdminApp extends React.Component{
    constructor (props){
        super (props)
        this.state = {
            tab: 'home'
        }
    }

    handletab = (e) => {
        e.preventDefault ()

        let id = e.target.id

        this.setState (update (this.state, {
            tab: {$set: id}
        }))
    }

    cerrarsesion = (e) => {
        e.preventDefault ()

        this.props.logoutUser()
    }

    render (){
        return (
            <div className='admin-app'>
                <div className='wrapper-item'>
                    <div className='wrapper-titulo'>
                        <h4>PANEL DE RESERVAS</h4>
                    </div>
                    <div className='wrapper-image'>
                        <img src='https://lacabreraperu.com/images/on_off.png' alt='logout'
                            onClick={this.cerrarsesion.bind (this)}/>
                    </div>
                </div>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className={this.state.tab === 'home' ? "nav-link active" : 'nav-link'}
                            aria-current="page" id='home' onClick={this.handletab.bind (this)}>Home</a>
                    </li>
                    <li className="nav-item">
                        <a className={this.state.tab === 'fechas' ? "nav-link active" : 'nav-link'}
                            id='fechas' onClick={this.handletab.bind (this)}>Fechas Activas/Inactivas</a>
                    </li>
                    <li className="nav-item">
                        <a className={this.state.tab === 'reservas' ? "nav-link active" : 'nav-link'}
                            id='reservas' onClick={this.handletab.bind (this)}>Lista de reservas</a>
                    </li>
                </ul>
                {
                    this.state.tab === 'home' ? (
                        <Home/>
                    ) : this.state.tab === 'reservas' ? (
                        <Reservas/>  
                    ) : this.state.tab === 'fechas' ? (
                        <Fechas/>
                    ) : null
                }
            </div>
        )
    }
}

AdminApp.propTypes = {
    logoutUser: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    logoutUser
}

export default connect(mapStateToProps, mapActionsToProps)(AdminApp)